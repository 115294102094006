// Styles
// **************************************
const common = 'mt-8 pt-8 border-t border-input break-words';
const title = 'mb-1 text-lg font-bold';

// Export
// **************************************
export const detailSectionsStyles = {
  common,
  title,
};
